import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IRevenueOutsourceCompaniesParams, IRevenueOutsourceCompaniesState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListROCS = createAsyncThunk(
  'listCompanies/fetchListROCS',
  async ({ query }: IRevenueOutsourceCompaniesParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.getROCS(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IRevenueOutsourceCompaniesState = {
  isLoading: false,
  response: null,
  error: null
}

const listROCSlice = createSlice({
  name: 'Companies/list',
  initialState,
  reducers: {
    resetListCompanies: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListROCS.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListROCS.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListROCS.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listROCSlice.reducer
export const { resetListCompanies } = listROCSlice.actions
