import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Grid, Group, LoadingOverlay} from '@mantine/core'
import {showNotification} from '@mantine/notifications'
import {MainLayout} from '../../layouts'
import {ContentBox, BackListButton, SaveButton, ResetButton} from '../../partials'
import {FormTypes} from '../../../types'
import {useAppDispatch, useAppSelector} from '../../../store'
import {ICompanyParams, CompanyType} from '../../../store/types'
import {IFormRef} from '../../../types'
import {getFormattedValidationErrors} from '../../../utils/mixins'
import Form from './Form'
import {fetchShowCompany, resetShowCompany} from '../../../store/features/companies/showCompanySlice'
import {fetchUpdateCompany, resetUpdateCompany} from '../../../store/features/companies/updateCompanySlice'

const Edit = () => {
  const {t} = useTranslation()
  const {id} = useParams<{ id: string }>()
  const navigate = useNavigate()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()
  const [data, setData] = useState<ICompanyParams | null>(null)

  const {
    isLoading: packageIsLoading,
    response: packageResponse,
    error: packageError
  } = useAppSelector(state => state.showCompany)
  const {isLoading, response, error} = useAppSelector(state => state.updateCompany)

  useEffect(() => {
    if (packageResponse === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowCompany(id as string))
      })()
    }
  }, [packageResponse, data, id, dispatch])

  const getSerializedData = (data: CompanyType) => {
    return {
      package: data.package?.id,
      name: data.name,
      state: data.state?.id,
      city: data.city?.id,
      address: data.address,
      zip: data.zip,
      person: data.person,
      phone: data.phone,
      email: data.email,
      blackbook_query_fee: data.blackbook_query_fee,
      rate_highway_company_id: data.rate_highway_company_id
    }
  }

  useEffect(() => {
    if (packageResponse !== null) {
      setData(getSerializedData(packageResponse.data))

      if (data !== null) {
        dispatch(resetShowCompany())
      }
    }
  }, [packageResponse, data, dispatch])

  useEffect(() => {
    if (packageError !== null) {
      if (packageError.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowCompany())
          await dispatch(fetchShowCompany(id))
        })()
      } else if (packageError.response.status === 404) {
        dispatch(resetShowCompany())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(packageError.data.message)
        })

        navigate('/companies')
      } else {
        dispatch(resetShowCompany())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/companies')
      }
    }
  }, [packageError, id, dispatch, t, navigate])

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('updated')
      })

      dispatch(resetUpdateCompany())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetUpdateCompany())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (params: ICompanyParams) => {
    await dispatch(fetchUpdateCompany({id: id as string, params}))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={packageIsLoading || isLoading || !data}/>
      {data && (
        <ContentBox title={t('content_actions.create')}>
          <Form
            id="edit-company-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/companies"/>
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-company-form"/>
              <ResetButton form="edit-company-form"/>
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Edit
