import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Badge, Divider, Grid, Group, LoadingOverlay, Text} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {MainLayout} from "../../layouts";
import {useAppDispatch, useAppSelector} from "../../../store";
import {BackListButton, ContentBox, ItemActionsMenu} from "../../partials";
import {fetchShowROC, resetShowROC} from "../../../store/features/revenueOutsourceCompanies/showROCSlice";
import Moment from "react-moment";
import {DATETIME_FORMAT} from "../../../types";
import {fetchDeleteROC} from "../../../store/features/revenueOutsourceCompanies/deleteROCSlice";

const Show = () => {
  const {t} = useTranslation();
  const {id} = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any | null>(null);
  const {isLoading, response, error} = useAppSelector(
    (state) => state.showROC
  );
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useAppSelector((state) => state.deleteROC);

  useEffect(() => {
    if (response === null && data === null) {
      (async () => {
        await dispatch(fetchShowROC(id as string));
      })();
    }
  }, [response, data, id, dispatch]);

  useEffect(() => {
    if (response !== null) {
      setData(response.data);

      if (data !== null) {
        dispatch(resetShowROC());
      }
    }
  }, [response, dispatch, data]);

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 401 && id) {
        (async () => {
          dispatch(resetShowROC());
          await dispatch(fetchShowROC(id));
        })();
      } else if (error.response.status === 404) {
        dispatch(resetShowROC());
        showNotification({
          color: "red",
          title: t("error"),
          message: t(error.data.message),
        });

        navigate("/revenue-outsource-companies");
      } else {
        dispatch(resetShowROC());
        showNotification({
          color: "red",
          title: t("error"),
          message: t("errors.unknown"),
        });

        navigate("/revenue-outsource-companies");
      }
    }
  }, [error, id, dispatch, t, navigate]);

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetShowROC());
      dispatch(resetShowROC());

      showNotification({
        color: "green",
        title: t("successful"),
        message: t("deleted"),
      });

      navigate("/revenue-outsource-companies");
    }
  }, [deleteResponse, t, dispatch, navigate]);

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetShowROC());

      showNotification({
        color: "red",
        title: t("unsuccessful"),
        message: deleteError.response.data
          ? deleteError.response.data.message
          : t("errors.unknown"),
      });
    }
  }, [deleteError, t, dispatch]);

  const handleDelete = async (id: string) => {
    await dispatch(fetchDeleteROC(id));
  };

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || deleteIsLoading}/>
      {data && (
        <>
          <ContentBox title={t("content_actions.show")}>
            <h4>{t("id")}:</h4>
            <Text>{data?.id}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t("name")}:</h4>
            <Text>{data.name}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t("companies")}:</h4>
            <Text>
              {data?.companies.map((item: any) => (
                <Badge mr="xs" mb="xs" component={Link} to={`/companies/${item.id}`} key={item.id}>{item.name}</Badge>
              ))}
            </Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t("access_key")}:</h4>
            <Text>
              {data.created_at ? (
                <>{data.access_key}</>
              ) : (
                <>-</>
              )}
            </Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t("status")}:</h4>
            <Text>{t(`statuses.${data.status}`)}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t("is_rate_highway_access")}:</h4>
            <Text>
              {data?.is_rate_highway_access ? (
                  <>{t("yes")}</>
              ) : (
                  <>{t("no")}</>
              )}
            </Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t("created_at")}:</h4>
            <Text>
              {data.created_at ? (
                <Moment format={DATETIME_FORMAT}>{data.created_at}</Moment>
              ) : (
                <>-</>
              )}
            </Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t("updated_at")}:</h4>
            <Text>
              {data.updated_at ? (
                <Moment format={DATETIME_FORMAT}>{data.updated_at}</Moment>
              ) : (
                <>-</>
              )}
            </Text>
          </ContentBox>
          <ContentBox>
            <Grid>
              <Grid.Col span={6}>
                <BackListButton to="/revenue-outsource-companies"/>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group position="right" pr="md">
                  <ItemActionsMenu
                    edit={`/revenue-outsource-companies/${data.id}/edit`}
                    onDelete={() => handleDelete(data.id)}
                  />
                </Group>
              </Grid.Col>
            </Grid>
          </ContentBox>
        </>
      )}
    </MainLayout>
  );
};

export default Show;
