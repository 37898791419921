import {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Grid, TextInput, Select, SelectItem, Textarea, Input, Text, NumberInput} from '@mantine/core'
import {useId} from '@mantine/hooks'
import InputMask from 'react-input-mask'
import {useForm, yupResolver} from '@mantine/form'
import * as Yup from 'yup'
import {IFormProps, IFormRef} from '../../../types'
import {useAppSelector, useAppDispatch} from '../../../store'
import {fetchListStates, resetListStates} from '../../../store/features/shared/listStatesSlice'
import {fetchListCities} from '../../../store/features/shared/listCitiesSlice'
import {fetchListPackages} from '../../../store/features/packages/listPackagesSlice'
import {fetchListEmployees} from '../../../store/features/employees/listEmployeesSlice'

const Form = forwardRef(({id, type, initialValues, onSubmit}: IFormProps, ref: Ref<IFormRef>) => {
  const {t} = useTranslation()
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useAppDispatch()
  const [stateOptions, setStateOptions] = useState<SelectItem[]>([])
  const [cityOptions, setCityOptions] = useState<SelectItem[]>([])
  const [packageOptions, setPackageOptions] = useState<SelectItem[]>([])
  const [initialized, setInitialized] = useState<boolean>(false)
  const {isLoading: statesIsLoading, response: statesResponse} = useAppSelector(state => state.listStates)
  const {isLoading: citiesIsLoading, response: citiesResponse} = useAppSelector(state => state.listCities)
  const {response} = useAppSelector(state => state.listPackages)
  const phoneId = useId()
  const schema = Yup.object().shape({
    email: Yup.string().required(t('validation.required')).email(t('validation.invalid')),
    package: Yup.string().required(t('validation.required')),
    name: Yup.string().required(t('validation.required')),
    state: Yup.string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    city: Yup.string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    address: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    person: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    blackbook_query_fee: Yup.number().nullable(),
    rate_highway_company_id: Yup.string().nullable()
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })
  useEffect(() => {
    if (!statesIsLoading && !citiesIsLoading && !initialized) {
      form.setValues(initialValues)
      setInitialized(true)
    }
  }, [statesIsLoading, citiesIsLoading, initialized, form, initialValues])

  useEffect(() => {
    if (statesResponse === null) {
      ;(async () => {
        await dispatch(fetchListStates())
      })()
    } else {
      setStateOptions(
        statesResponse.data.map((state: any) => ({
          value: state.id,
          label: `${state.name} (${state.code})`
        }))
      )
    }
  }, [statesResponse, dispatch])
  // package
  useEffect(() => {
    dispatch(
      fetchListPackages({
        query: {
          page: 1,
          per: 1000
        }
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (response) {
      setPackageOptions(
        response.data.items.map((packageItem: any) => ({
          value: packageItem.id,
          label: packageItem.name
        }))
      )
    }
  }, [response])
  // user
  useEffect(() => {
    dispatch(
      fetchListEmployees({
        query: {
          page: 1,
          per: 1000
        }
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (citiesResponse) {
      setCityOptions(
        citiesResponse.data.map((city: any) => ({
          value: city.id,
          label: city.name
        }))
      )
      dispatch(resetListStates())
    }
  }, [citiesResponse, dispatch])

  useEffect(() => {
    if (initialValues.state) {
      ;(async () => {
        await dispatch(fetchListCities(initialValues.state))
      })()
    }
  }, [initialValues, dispatch])

  useEffect(() => {
    if (!statesIsLoading && !citiesIsLoading && !initialized) {
      form.setValues(initialValues)
      setInitialized(true)
    }
  }, [statesIsLoading, citiesIsLoading, initialized, form, initialValues])

  const handleStateChange = async (value: string) => {
    form.setFieldValue('state', value ?? '')
    form.setFieldValue('city', '')

    if (value) {
      await dispatch(fetchListCities(value))
    } else {
      setCityOptions([])
    }
  }
  const handleReset = () => {
    form.reset()
    setInitialized(false)
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => {
        onSubmit(schema.cast(data))
      })}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('pages.package.name')}
            placeholder="Company name"
            required
            {...form.getInputProps('name')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            type="email"
            label={t('email')}
            placeholder="example@mail.com"
            required
            {...form.getInputProps('email')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Text style={{marginBottom: '4px', fontSize: '14px', fontWeight: 500, color: '#212529'}}>Phone</Text>

          <Input
            id={phoneId}
            required
            component={InputMask}
            mask="(999) 999-9999"
            {...form.getInputProps('phone')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label="Package"
            placeholder={t('select')}
            data={packageOptions}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            required
            {...form.getInputProps('package')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            type="text"
            label="Person Name"
            placeholder="John Doe"
            {...form.getInputProps('person')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('state')}
            placeholder={t('select')}
            data={stateOptions}
            disabled={statesIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('state')}
            onChange={(value: string) => handleStateChange(value)}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('city')}
            placeholder={t('city')}
            data={cityOptions}
            disabled={statesIsLoading || citiesIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('city')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Textarea
            label="Address"
            placeholder="Address"
            {...form.getInputProps('address')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label="Zip code"
            placeholder="Zip code"
            {...form.getInputProps('zip')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            label="Blackbook query fee"
            defaultValue={0.5}
            precision={2}
            min={0}
            step={0.05}
            {...form.getInputProps('blackbook_query_fee')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label="Rate Highway Company ID"
            placeholder="Rate Highway Company ID"
            {...form.getInputProps('rate_highway_company_id')}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
