const translation = {
  email: 'E-posta',
  password: 'Şifre',
  password_confirmation: 'Password doğrulama',
  remember_me: 'Beni hatırla',
  login: 'Giriş',
  logout: 'Çıkış',
  profile: 'Profil',
  dashboard: 'Başlangıç',
  go_to_dashboard: 'Başlangıca git',
  go_to_login: 'Girişe git',
  filter: 'Filtrele',
  user: 'Kullanıcı',
  users: 'Kullanıcılar',
  package: 'Paket',
  packages: 'Paketler',
  id: 'ID',
  name: 'İsim',
  lastname: 'Soyisim',
  person: 'Kişi',
  phone: 'Telefon',
  role: 'Rol',
  roles: 'Roller',
  external_account: 'Harici hesap',
  external_accounts: 'Harici hesaplar',
  external_account_types: {
    zubie: 'Zubie',
    tsd: 'TSD',
  },
  key: 'Anahtar',
  token_type: 'Token tipi',
  access_token: 'Erişim anahtarı',
  refresh_token: 'Yenileme anahtarı',
  expires_in: 'Bitiş süresi',
  account_id: 'Hesap ID',
  username: 'Kullanıcı adı',
  is_re_access_required: 'Yeniden erişim gerekli',
  state: 'Eyalet',
  city: 'Şehir',
  address: 'Adres',
  zip: 'Posta kodu',
  actions: 'Eylemler',
  select: 'Seçin',
  keywords: 'Anahtar kelimeler',
  per_page: 'Sayfa başına',
  total: 'Toplam',
  confirmation_required: 'Onay gerekli',
  delete_confirmation_message: 'Silmek istediğinize emin misiniz?',
  stop_continually_message: 'Yinelemeyi durdurmak istediğinize emin misiniz?',
  do_you_want_to_send: 'Göndermek istiyor musunuz?',
  reports_sent: 'Raporlar gönderildi',
  reports_could_not_be_sent: 'Raporlar gönderilemedi',
  yes: 'Evet',
  no: 'Hayır',
  cancel: 'İptal',
  error: 'Hata',
  successful: 'Başarılı',
  unsuccessful: 'Başarısız',
  is_activated: 'Aktif mi?',
  future_value: 'Gelecek değer',
  history_reports: 'Geçmiş raporları',
  weekly_reports: 'Haftalık raportlar',
  class_performances: 'Klas performansları',
  price_alert_permit_count: 'Fiyat uyarısı izin sayısı',
  projected_rev_loss: 'Öngörülen gelir kaybı',
  financing_companies: 'Finans Kuruluşları',
  vehicles: 'Araçlar',
  vin: 'VIN',
  unit: 'Unit',
  class: 'Sınıf',
  classes: 'Sınıflar',
  class_name: 'Sınıf adı',
  group: 'Grup',
  groups: 'Gruplar',
  group_name: 'Grup adı',
  in_service_date: 'Servise giriş tarihi',
  brand: 'Marka',
  brands: 'Markalar',
  model: 'Model',
  models: 'Modeller',
  model_detail: 'Model Detayı',
  model_details: 'Model Detayları',
  model_year: 'Model Yılı',
  color: 'Renk',
  mileage: 'Mil',
  condition: 'Durum',
  conditions: {
    excellent: 'Mükemmel',
    good: 'İyi',
    fair: 'Orta',
  },
  company: 'Firma',
  companies: 'Firmalar',
  rate_highway_company_id: 'Rate Highway Company ID',
  is_rate_highway_access: 'Rate Highway erişimi var mı?',
  monthly_average_fleet_size: 'Aylık ortalama filo büyüklüğü',
  blackbook_query_fee: 'Blackbook sorgu ücreti',
  location: 'Konum',
  locations: 'Lokasyonlar',
  admin: 'Yönetici',
  employee: 'Çalışan',
  employees: 'Çalışanlar',
  invoice: 'Fatura',
  invoices: 'Faturalar',
  months: 'Aylar',
  amount: 'Miktar',
  description: 'Açıklama',
  continually: 'Sürekli',
  due_date: 'Vade tarihi',
  date: 'Tarih',
  report_logs: 'Rapor kayıtları',
  payment_status: 'Ödeme durumu',
  all: 'Tümü',
  paid: 'Ödenmiş',
  unpaid: 'Ödenmemiş',
  paid_date: 'Ödenme tarihi',
  next_invoice_date: 'Sonraki fatura tarihi',
  transaction: 'İşlem',
  transactions: 'İşlemler',
  transaction_details: 'İşlem detayları',
  email_logs: 'E-mail kayıtları',
  provider: 'Sağlayıcı',
  providers: 'Sağlayıcılar',
  session_id: 'Oturum ID',
  payment_providers: {
    stripe: 'Stripe',
  },
  transaction_statuses: {
    waiting: 'Bekliyor',
    succeeded: 'Başarılı',
    failed: 'Başarısız',
    refunded: 'İade edildi',
    cancelled: 'İptal edildi',
  },
  data: 'Veri',
  results: 'Sonuçlar',
  details: 'Detaylar',
  completed_at: 'Tamamlanma tarihi',
  refund_at: 'İade tarihi',
  access_key: 'Erişim anahtarı',
  activated_at: 'Aktif olma tarihi',
  blocked_at: 'Engellenme tarihi',
  created: 'Oluşturuldu',
  updated: 'Güncellendi',
  deleted: 'Silindi',
  stopped: 'Durduruldu',
  email_resent: 'Email resent',
  last_login_at: 'Son giriş tarihi',
  created_at: 'Oluşturma tarihi',
  updated_at: 'Gncellenme tarihi',
  deleted_at: 'Silinme tarihi',
  role_names: {
    ROLE_USER: 'Kullanıcı',
    ROLE_ADMIN: 'Yönetici'
  },
  status: 'Durum',
  statuses: {
    active: 'Aktif',
    inactive: 'Pasif',
  },
  content_actions: {
    list: 'Liste',
    create: 'Oluştur',
    show: 'Görüntüle',
    edit: 'Düzenle',
    stop_continually: 'Yinelemeyi durdur',
    delete: 'Sil',
    save: 'Kaydet',
    cancel: 'İptal',
    import: 'İçeri aktar',
    export: 'Dışarı aktar',
    impersonate: 'Impersonate',
    resend: 'Tekrar gönder',
    send_reports: 'Raportları gönder',
    show_report_logs: 'Rapor kayıtlarını göster',
  },
  please_wait: 'Lütfen bekleyin',
  loading: 'Yükleniyor',
  theme: {
    dark: 'Karanlık',
    light: 'Aydınlık'
  },
  validation: {
    required: 'Bu alan gerekli',
    invalid: 'Geçersiz değer',
    min: 'En az {{length}} karakter olmalı',
    max: 'En fazla {{length}} karakter olmalı',
    min_select: 'En az {{length}} tane seçilmeli',
    max_select: 'En fazla {{length}} tane seçilebilir',
    do_not_much: 'Aynı değil'
  },
  errors: {
    bad_credentials: 'Kimlik bilgileri hatalı!',
    auth_forbidden: 'Yetkisiz erişim!',
    unknown: 'Bilinmeyen bir sorun oluştu!',
    content_not_found: 'İçerik bulunamadı!',
    no_options: 'Seçenek yok'
  },
  pages: {
    package: {
      name: 'İsim',
      description: 'Açıklama',
      fleet_size: 'Filo büyüklüğü',
      manheim_query_limit: 'Manheim sorgu limiti',
      annual_fee: 'Yıllık ücret',
      monthly_fee: 'Aylık ücret',
      is_demo: 'Demo',
    }
  },
  please_try_again: 'Lütfen tekrar deneyin,',
  code: 'Kod',
  region: 'Bölge',
  monthly_mileage_assumption: 'Aylık mil varsayımı',
  monthly_depreciation_assumption: 'Aylık amortisman varsayımı',
  finance_length: 'Finans süresi',
  zubie_location_name: 'Zubie lokasyon adı',
  pickup_location_number: 'Araç teslim lokasyon numarası',
}

export default translation
