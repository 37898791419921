import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import {CompanyType, IUserParams, UserType} from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import { fetchShowUser, resetShowUser } from '../../../store/features/users/showUserSlice'
import { fetchUpdateUser, resetUpdateUser } from '../../../store/features/users/updateUserSlice'
import {fetchListCompanies} from "../../../store/features/companies/listCompanySlice";

const Edit = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()
  const [data, setData] = useState<IUserParams | null>(null)

  const { isLoading: userIsLoading, response: userResponse, error: userError } = useAppSelector(state => state.showUser)
  const { isLoading, response, error } = useAppSelector(state => state.updateUser)

  useEffect(() => {
    if (userResponse === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowUser(id as string))
      })()
    }
  }, [userResponse, data, id, dispatch])

  const getSerializedData = (data: UserType) => {
    return {
      roles: data.roles,
      name: data.name,
      last_name: data.last_name,
      email: data.email,
      password: '',
      password_confirmation: '',
      state: data.state ? data.state.id : '',
      city: data.city ? data.city.id : '',
      address: data.address,
      zip: data.zip,
      is_activated: data.activated_at !== null,
      is_future_value: data.is_future_value,
      is_projected_rev_loss: data.is_projected_rev_loss,
      is_history_reports: data.is_history_reports,
      is_weekly_reports: data.is_weekly_reports,
      is_class_performances: data.is_class_performances,
      price_alert_permit_count: data.price_alert_permit_count,
      employees: data.employees?.length > 0 ? data.employees.map(employee => ({
        company_id: employee.company!.id,
        is_admin: employee.is_admin
      })) : []
    }
  }

  useEffect(() => {
    if (userResponse !== null) {
      setData(getSerializedData(userResponse.data))

      if (data !== null) {
        dispatch(resetShowUser())
      }
    }
  }, [userResponse, data, dispatch])

  useEffect(() => {
    if (userError !== null) {
      if (userError.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowUser())
          await dispatch(fetchShowUser(id))
        })()
      } else if (userError.response.status === 404) {
        dispatch(resetShowUser())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(userError.data.message)
        })

        navigate('/users')
      } else {
        dispatch(resetShowUser())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/users')
      }
    }
  }, [userError, id, dispatch, t, navigate])

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('updated')
      })

      dispatch(resetUpdateUser())
      dispatch(resetShowUser())
      window.location.reload()
    }
  }, [response, t, dispatch, id])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetUpdateUser())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const {
    isLoading: companiesIsLoading,
    response: companiesResponse
  } = useAppSelector(state => state.listCompany)
  const [companies, setCompanies] = useState<CompanyType[]>([])
  useEffect(() => {
    dispatch(fetchListCompanies({ query: {page: 1, per: 999999} }))
  }, [dispatch])

  useEffect(() => {
    if (companiesResponse !== null) {
      setCompanies(companiesResponse.data.items)
    }
  }, [companiesResponse])

  const handleSubmit = async (params: IUserParams) => {
    if (!params.password || params.password === '') {
      delete params.password
      delete params.password_confirmation
    }

    await dispatch(fetchUpdateUser({ id: id as string, params }))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={userIsLoading || isLoading || companiesIsLoading || !data} />
      {data && (
        <ContentBox title={t('content_actions.create')}>
          <Form
            id="edit-user-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            companies={companies}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/users" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-user-form" />
              <ResetButton form="edit-user-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Edit
