import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Badge, Divider, Grid, Group, LoadingOverlay, Table, Text} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import Moment from "react-moment";
import {MainLayout} from "../../layouts";
import {useAppDispatch, useAppSelector} from "../../../store";
import {ExternalAccountType, UserType} from "../../../store/types";
import {BackListButton, ContentBox, UserItemActionsMenu} from "../../partials";
import {DATETIME_FORMAT} from "../../../types";
import {fetchShowUser, resetShowUser} from "../../../store/features/users/showUserSlice";
import {fetchDeleteUser, resetDeleteUser} from "../../../store/features/users/deleteUserSlice";
import {app} from "../../../config";
import AdminApiClient from "../../../utils/http-clients/AdminApiClient";
import ReportLogsModal from "./ReportLogsModal";
import {useModals} from "@mantine/modals";
import ExternalAccountModal from "./ExternalAccountModal";

const Show = () => {
  const {t} = useTranslation();
  const {id} = useParams<{ id: string }>();
  const modals = useModals();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const adminApiClient = AdminApiClient.getInstance()
  const [data, setData] = useState<UserType | null>(null);
  const [reportLogsUser, setReportLogsUser] = useState<UserType | null>(null);
  const [externalAccount, setExternalAccount] = useState<ExternalAccountType | null>(null);
  const [isReportsSending, setIsReportsSending] = useState(false);
  const {isLoading, response, error} = useAppSelector(state => state.showUser);
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useAppSelector(state => state.deleteUser);

  useEffect(() => {
    if (response === null && data === null) {
      (async () => {
        await dispatch(fetchShowUser(id as string));
      })();
    }
  }, [response, data, id, dispatch]);

  useEffect(() => {
    if (response !== null) {
      setData(response.data);

      if (data !== null) {
        dispatch(resetShowUser());
      }
    }
  }, [response, dispatch, data]);

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 401 && id) {
        (async () => {
          dispatch(resetShowUser());
          await dispatch(fetchShowUser(id));
        })();
      } else if (error.response.status === 404) {
        dispatch(resetShowUser());
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(error.data.message),
        });

        navigate('/users');
      } else {
        dispatch(resetShowUser());
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown'),
        });

        navigate('/users');
      }
    }
  }, [error, id, dispatch, t, navigate]);

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetShowUser());
      dispatch(resetDeleteUser());

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('deleted'),
      });

      navigate('/users');
    }
  }, [deleteResponse, t, dispatch, navigate]);

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetShowUser());

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: deleteError.response.data ? deleteError.response.data.message : t('errors.unknown'),
      });
    }
  }, [deleteError, t, dispatch]);

  const impersonateUser = async (id: string) => {
    try {
      const response = await adminApiClient.impersonateUser(id)
      const token = await response.data.data.token
      if (token) {
        const url = `${app.FRONTEND_BASE_URL}?token=${token}`
        window.open(url, '_blank')
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const sendReports = async (id: string) => {
    setIsReportsSending(true)
    try {
      await adminApiClient.sendReportsUser(id)
      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('reports_sent')
      })
    } catch (error: any) {
      showNotification({
        color: 'red',
        title: t('error'),
        message: t('reports_could_not_be_sent')
      })
    } finally {
      setIsReportsSending(false)
    }
  }

  const handleSendReports = async (id: string) => {
    modals.openConfirmModal({
      title: t('confirmation_required'),
      centered: true,
      children: <Text size="sm">{t('do_you_want_to_send')}</Text>,
      labels: {confirm: t('yes'), cancel: t('no')},
      confirmProps: {color: 'red'},
      onCancel: () => {
      },
      onConfirm: async () => await sendReports(id)
    })
  }

  const handleDelete = async (id: string) => {
    await dispatch(fetchDeleteUser(id));
  };

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || deleteIsLoading || isReportsSending}/>
      {data &&
        <>
          <ContentBox title={t('content_actions.show')}>
            <h4>{t('id')}:</h4>
            <Text>{data.id}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('name')}:</h4>
            <Text>{data.name}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('lastname')}:</h4>
            <Text>{data.last_name}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('email')}:</h4>
            <Text variant="link"
                  component="a"
                  href={`mailto:${data.email}`}>{data.email}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('roles')}:</h4>
            <Text>
              {data.roles.map((role, key) => (
                <Badge variant="outline"
                       key={key}
                       mr="xs"
                       color="gray">{t(`role_names.${role}`)}</Badge>
              ))}
            </Text>

            <h4>{t('external_accounts')}:</h4>
            <Text>
              {data.external_accounts.map((account, key) => (
                <Badge variant="outline"
                       key={key}
                       mr="xs"
                       color="gray"
                       style={{cursor: 'pointer'}}
                       onClick={() => setExternalAccount(account)}>
                  {t(`external_account_types.${account.provider}`)}
                </Badge>
              ))}
            </Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('future_value')}:</h4>
            <Text>{data.is_future_value ? t('yes') : t('no')}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('projected_rev_loss')}:</h4>
            <Text>{data.is_projected_rev_loss ? t('yes') : t('no')}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('history_reports')}:</h4>
            <Text>{data.is_history_reports ? t('yes') : t('no')}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('weekly_reports')}:</h4>
            <Text>{data.is_weekly_reports ? t('yes') : t('no')}</Text>

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('class_performances')}:</h4>
            <Text>{data.is_class_performances ? t('yes') : t('no')}</Text>

            <h4>{t('price_alert_permit_count')}:</h4>
            <Text>{data.price_alert_permit_count}</Text>

            <h4>{t('companies')}:</h4>
            {data.employees.length > 0 ? (
                <Table striped highlightOnHover>
                    <thead>
                    <tr>
                        <th>{t('company')}</th>
                        <th>{t('is_admin')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.employees.map((employee, key) => (
                        <tr key={key}>
                            <td>{employee.company ? employee.company.name : '-'}</td>
                            <td>{employee.is_admin ? t('yes') : t('no')}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <Text>-</Text>
            )}

            {data.activated_at &&
              <>
                <Divider variant="dashed" size="xs" mt="sm"/>
                <h4>{t('activated_at')}:</h4>
                <Text>
                  <Moment format={DATETIME_FORMAT}>{data.activated_at}</Moment>
                </Text>
              </>
            }

            <Divider variant="dashed" size="xs" mt="sm"/>

            <h4>{t('created_at')}:</h4>
            <Text>
              <Moment format={DATETIME_FORMAT}>{data.created_at}</Moment>
            </Text>

            {data.last_login_at &&
              <>
                <Divider variant="dashed" size="xs" mt="sm"/>
                <h4>{t('last_login_at')}:</h4>
                <Text>
                  <Moment format={DATETIME_FORMAT}>{data.last_login_at}</Moment>
                </Text>
              </>
            }

            {data.created_at !== data.updated_at &&
              <>
                <Divider variant="dashed" size="xs" mt="sm"/>
                <h4>{t('updated_at')}:</h4>
                <Text>
                  <Moment format={DATETIME_FORMAT}>{data.updated_at}</Moment>
                </Text>
              </>
            }

            {data.blocked_at &&
              <>
                <Divider variant="dashed" size="xs" mt="sm"/>
                <h4>{t('blocked_at')}:</h4>
                <Text>
                  <Moment format={DATETIME_FORMAT}>{data.blocked_at}</Moment>
                </Text>
              </>
            }

            {data.deleted_at &&
              <>
                <Divider variant="dashed" size="xs" mt="sm"/>
                <h4>{t('deleted_at')}:</h4>
                <Text>
                  <Moment format={DATETIME_FORMAT}>{data.deleted_at}</Moment>
                </Text>
              </>
            }
          </ContentBox>
          <ContentBox>
            <Grid>
              <Grid.Col span={6}>
                <BackListButton to="/users"/>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group position="right" pr="md">
                  <UserItemActionsMenu edit={`/users/${data.id}/edit`}
                                       impersonate={() => impersonateUser(data.id)}
                                       showReportLogs={() => setReportLogsUser(data)}
                                       sendReports={() => handleSendReports(data.id)}
                                       onDelete={() => handleDelete(data.id)}/>
                </Group>
              </Grid.Col>
            </Grid>
            <ReportLogsModal user={reportLogsUser} onClose={() => setReportLogsUser(null)}/>
            <ExternalAccountModal account={externalAccount} onClose={() => setExternalAccount(null)}/>
          </ContentBox>
        </>
      }
    </MainLayout>
  );
};

export default Show;
