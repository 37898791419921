import {useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import {CompanyType, IUserParams} from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import { fetchCreateUser, resetCreateUser } from '../../../store/features/users/createUserSlice'
import {fetchListCompanies} from "../../../store/features/companies/listCompanySlice";

const Create = () => {
  const { t } = useTranslation()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()

  const { isLoading, response, error } = useAppSelector(state => state.createUser)

  useEffect(() => {
    if (response !== null) {
      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('created')
      })

      dispatch(resetCreateUser())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetCreateUser())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const {
    isLoading: companiesIsLoading,
    response: companiesResponse
  } = useAppSelector(state => state.listCompany)
  const [companies, setCompanies] = useState<CompanyType[]>([])
  useEffect(() => {
    dispatch(fetchListCompanies({ query: {page: 1, per: 999999} }))
  }, [dispatch])

  useEffect(() => {
    if (companiesResponse !== null) {
      setCompanies(companiesResponse.data.items)
    }
  }, [companiesResponse])

  const handleSubmit = async (data: IUserParams) => {
      console.log('data', data)
    await dispatch(fetchCreateUser(data))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || companiesIsLoading} />
      <ContentBox title={t('content_actions.create')}>
        <Form
          id="create-user-form"
          type={FormTypes.CREATE}
          ref={formRef}
          initialValues={{
            roles: [],
            name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            state: '',
            city: '',
            address: '',
            zip: '',
            is_activated: false,
            is_future_value: false,
            is_projected_rev_loss: false,
            is_history_reports: false,
            is_weekly_reports: false,
            price_alert_permit_count: 0,
            employees: []
          }}
          companies={companies}
          onSubmit={handleSubmit}
        />
      </ContentBox>
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/users" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="create-user-form" />
              <ResetButton form="create-user-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Create
