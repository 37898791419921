const translation = {
  email: 'E-mail',
  password: 'Password',
  password_confirmation: 'Password confirmation',
  remember_me: 'Remember me',
  login: 'Login',
  logout: 'Logout',
  profile: 'Profile',
  dashboard: 'Dashboard',
  go_to_dashboard: 'Go to dashboard',
  go_to_login: 'Go to login',
  filter: 'Filter',
  user: 'User',
  users: 'Users',
  package: 'Package',
  packages: 'Packages',
  id: 'ID',
  name: 'Name',
  lastname: 'Lastname',
  person: 'Person',
  phone: 'Phone',
  role: 'Role',
  roles: 'Roles',
  external_account: 'External account',
  external_accounts: 'External accounts',
  external_account_types: {
    zubie: 'Zubie',
    tsd: 'TSD',
  },
  key: 'Key',
  token_type: 'Token type',
  access_token: 'Access token',
  refresh_token: 'Refresh token',
  expires_in: 'End time',
  account_id: 'Account ID',
  username: 'Username',
  is_re_access_required: 'Is re access required',
  state: 'State',
  city: 'City',
  address: 'Address',
  zip: 'Zip',
  actions: 'Actions',
  select: 'Select',
  keywords: 'Keywords',
  per_page: 'Per page',
  total: 'Total',
  confirmation_required: 'Confirmation required',
  delete_confirmation_message: 'Are you sure you want to delete?',
  stop_continually_message: 'Are you sure you want to stop continually?',
  do_you_want_to_send: 'Do you want to send?',
  reports_sent: 'Reports sent',
  reports_could_not_be_sent: 'Reports could not be sent',
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  error: 'Error',
  successful: 'Successful',
  unsuccessful: 'Unsuccessful',
  is_activated: 'Is activated?',
  future_value: 'Future value',
  history_reports: 'History reports',
  weekly_reports: 'Weekly reports',
  class_performances: 'Class performances',
  price_alert_permit_count: 'Price alert permit count',
  projected_rev_loss: 'Projected revenue loss',
  financing_companies: 'Financing Companies',
  vehicles: 'Vehicles',
  vin: 'VIN',
  unit: 'Unit',
  class: 'Class',
  classes: 'Classes',
  class_name: 'Class name',
  group: 'Group',
  groups: 'Groups',
  group_name: 'Group name',
  in_service_date: 'In service date',
  brand: 'Brand',
  brands: 'Brands',
  model: 'Model',
  models: 'Models',
  model_detail: 'Model Detail',
  model_details: 'Model Details',
  model_year: 'Model Year',
  color: 'Color',
  mileage: 'Mileage',
  condition: 'Condition',
  conditions: {
    excellent: 'Above',
    good: 'Average',
    fair: 'Below',
  },
  company: 'Company',
  companies: 'Companies',
  rate_highway_company_id: 'Rate Highway Company ID',
  is_rate_highway_access: 'Is rate highway access?',
  monthly_average_fleet_size: 'Monthly average fleet size',
  blackbook_query_fee: 'Blackbook query fee',
  location: 'Location',
  locations: 'Locations',
  admin: 'Admin',
  employee: 'Employee',
  employees: 'Employees',
  invoice: 'Invoice',
  invoices: 'Invoices',
  months: 'Months',
  amount: 'Amount',
  description: 'Description',
  continually: 'Continually',
  due_date: 'Due Date',
  date: 'Date',
  report_logs: 'Report logs',
  payment_status: 'Payment status',
  all: 'All',
  paid: 'Paid',
  unpaid: 'Unpaid',
  paid_date: 'Paid date',
  next_invoice_date: 'Next invoice date',
  transaction: 'Transaction',
  transactions: 'Transactions',
  transaction_details: 'Transaction details',
  email_logs: 'E-mail logs',
  provider: 'Provider',
  providers: 'Providers',
  session_id: 'Session ID',
  payment_providers: {
    stripe: 'Stripe',
  },
  transaction_statuses: {
    waiting: 'Waiting',
    succeeded: 'Succeeded',
    failed: 'Failed',
    refunded: 'Refunded',
    cancelled: 'Canceled',
  },
  data: 'Data',
  results: 'Results',
  details: 'Details',
  completed_at: 'Completed at',
  refund_at: 'Refund at',
  access_key: 'Access key',
  activated_at: 'Activated at',
  blocked_at: 'Blocked at',
  created: 'Created',
  updated: 'Updated',
  deleted: 'Deleted',
  stopped: 'Stopped',
  email_resent: 'Email resent',
  last_login_at: 'Last login at',
  created_at: 'Created at',
  updated_at: 'Updated at',
  deleted_at: 'Deleted at',
  role_names: {
    ROLE_USER: 'User',
    ROLE_ADMIN: 'Admin'
  },
  status: 'Status',
  statuses: {
    active: 'Active',
    inactive: 'Inactive',
  },
  content_actions: {
    list: 'List',
    create: 'Create',
    show: 'Show',
    edit: 'Edit',
    stop_continually: 'Stop continually',
    delete: 'Delete',
    save: 'Save',
    cancel: 'İptal',
    import: 'Import',
    export: 'Export',
    impersonate: 'Impersonate',
    resend: 'Resend',
    send_reports: 'Send reports',
    show_report_logs: 'Show report logs',
  },
  please_wait: 'Lütfen bekleyin',
  loading: 'Yükleniyor',
  theme: {
    dark: 'Dark',
    light: 'Light'
  },
  validation: {
    required: 'This field is required',
    invalid: 'This field is invalid',
    min: 'Should have at least {{length}} letters',
    max: 'Must contain at most {{length}} letters',
    min_select: 'Should have at least {{length}} selections',
    max_select: 'Must contain at most {{length}} selections',
    do_not_much: 'Do not match'
  },
  errors: {
    bad_credentials: 'Bad credentials!',
    auth_forbidden: 'Forbidden access!',
    unknown: 'An unknown problem has occurred!',
    content_not_found: 'Content not found!',
    no_options: 'No options'
  },
  pages: {
    package: {
      name: 'Name',
      description: 'Description',
      fleet_size: 'Fleet size',
      manheim_query_limit: 'Manheim query limit',
      annual_fee: 'Annual fee',
      monthly_fee: 'Monthly fee',
      is_demo: 'Is demo'
    }
  },
  please_try_again: 'Please try again,',
  revenue_outsource_companies: 'Revenue Outsource Companies',
  code: 'Code',
  region: 'Region',
  monthly_mileage_assumption: 'Monthly mileage assumption',
  monthly_depreciation_assumption: 'Monthly depreciation assumption',
  finance_length: 'Finance length',
  zubie_location_name: 'Zubie location name',
  pickup_location_number: 'Pickup location number',
}

export default translation
